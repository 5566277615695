import Cookies from "js-cookie";

const app = {
  state: {
    device: "desktop",
    language: Cookies.get("language") || "de",
    size: {
      screen: {
        width: 0,
        height: 0
      },
      header: {
        height: 0
      }
    },
    mainnav: [],
    navLevel: 0,
    navVisible: false,
    tourActive: false
  },
  mutations: {
    TOGGLE_DEVICE: (state, device) => {
      state.device = device;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      Cookies.set("language", language);
    },
    SET_SIZE: (state, size) => {
      state.size.screen.width = size.screen.width;
      state.size.screen.height = size.screen.height;
    },
    SET_HEADER_HEIGHT: (state, height) => {
      state.size.header.height = height;
    },
    SET_MAINNAV: (state, nav) => {
      state.mainnav = nav;
    },
    SET_TOUR_ACTIVE: (state, value) => {
      state.tourActive = value;
    }
  },
  actions: {
    toggleDevice({ commit }, device) {
      commit("TOGGLE_DEVICE", device);
    },
    setLanguage({ commit }, language) {
      commit("SET_LANGUAGE", language);
    },
    setSize({ commit }, size) {
      commit("SET_SIZE", size);
    },
    setHeaderHeight({ commit }, height) {
      commit("SET_HEADER_HEIGHT", height);
    },    
    setMainnav({ commit }, nav) {
      commit("SET_MAINNAV", nav);
    },
    toggleNav({ commit }) {
      commit("SET_NAVVISIBLE", !app.state.navVisible);
    },
    setTourActive({ commit }, value) {
      commit("SET_TOUR_ACTIVE", value);
    }
  }
};

export default app;
