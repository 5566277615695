import Vue from "vue";
import Router from "vue-router";
import Layout from "@/views/layout/";

Vue.use(Router);

export const constantRouterMap = [
  {
    path: "",
    component: Layout,
    redirect: "home",
    meta: {
      title: "home",
      uid: 1,
      display: 0,
      type: "mainnav",
      dataType: "html",
      hasOverflow: false
    },
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index"),
        name: "home",
        meta: {
          title: "home",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
          hasOverflow: false
        }
      },
      {
        path: "/collection-winter",
        component: () => import("@/views/collectionwinter/index"),
        name: "collection-winter",
        meta: {
          title: "collection winter",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
        }
      },
      {
        path: "/collection-summer",
        component: () => import("@/views/collectionsummer/index"),
        name: "collection-summer",
        meta: {
          title: "collection summer",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
        }
      },
      {
        path: "/technology",
        component: () => import("@/views/technology/index"),
        name: "technology",
        meta: {
          title: "technology",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
        }
      },
      {
        path: "/sustainability",
        component: () => import("@/views/sustainability/index"),
        name: "sustainability",
        meta: {
          title: "sustainability",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
        }
      },
      {
        path: "/partners",
        component: () => import("@/views/partners/index"),
        name: "partners",
        meta: {
          title: "partners",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
        }
      },
      {
        path: "/vision-mission",
        component: () => import("@/views/vision/index"),
        name: "vision-mission",
        meta: {
          title: "vision+mission",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
        }
      },      
      {
        path: "/team",
        component: () => import("@/views/team/index"),
        name: "team",
        meta: {
          title: "team",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
        }
      },      
      {
        path: "/history",
        component: () => import("@/views/history/index"),
        name: "history",
        meta: {
          title: "history",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
        }
      },
      {
        path: "/mymountainpassion",
        component: () => import("@/views/mymountainpassion/index"),
        name: "mymountainpassion",
        meta: {
          title: "#mymountainpassion",
          uid: 1,
          noCache: true,
          type: "mainnav",
          display: 0,
          dataType: "html",
        }
      }

    ]
  }
];

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: constantRouterMap
});
