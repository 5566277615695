import Vue from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/assets/scss/app.scss";
import VueI18n from "vue-i18n";
import translations from "./resources/translations";
import Cookies from "js-cookie";
import "./permission.js";

Vue.config.productionTip = false;

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: Cookies.get("language") || "de",
  fallbackLocale: "en",
  messages: translations
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
