export default {
  en: {
    label: {},
    link: {
      more: "more"
    },
  },
  de: {
    label: {},
    link: {
      more: "mehr"
    },
  }
};
