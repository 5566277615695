<template>
      <header role="banner" aria-label="Mainheader" ref="domHeader">
        <MainNav :items="mainNavItems" />
        <div class="language-contact">
            <p class="language" v-if="false">
                <a href="/de" class="link--lang">de</a> / <a href="/en" class="link--lang">en</a>
            </p>
            <a href="mailto:info@zanier.com" class="link--contact">
                <i class="svgicon svgicon--mail">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 35.6 21" overflow="visible" enable-background="new 0 0 35.6 21" xml:space="preserve"><rect x="0.6" y="0.6" fill="none" stroke="#FFFFFF" stroke-width="1.2" stroke-miterlimit="10" width="34.4" height="19.8"/><polyline fill="none" stroke="#FFFFFF" stroke-width="1.2" stroke-miterlimit="10" points="0.6,0.6 17.8,10.5 35,0.6"/><line fill="none" stroke="#FFFFFF" stroke-width="1.2" stroke-miterlimit="10" x1="35" y1="20.4" x2="21.8" y2="12.8"/><line fill="none" stroke="#FFFFFF" stroke-width="1.2" stroke-miterlimit="10" x1="13.6" y1="13" x2="0.6" y2="20.4"/></svg>
                </i>
            </a>
        </div>
        <div class="logo">
            <figure>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="232.5px" height="41.7px" viewBox="0 0 232.5 41.7" overflow="visible" enable-background="new 0 0 232.5 41.7" xml:space="preserve"><g><g><polygon fill="#FFFFFF" points="56.2,14.5 29,41.7 0,41.7 41.7,0 		"/><polygon fill="#FFFFFF" points="82.6,26 73.6,35 45,35 68.3,11.7 		"/>	</g><g><g><polygon fill="#FFFFFF" points="141.5,17.4 125.8,33.1 116.9,33.1 137,12.9 			"/></g><g><polygon fill="#FFFFFF" points="147.5,23.4 137.8,33.1 128.8,33.1 143,18.9 			"/></g><path fill="#FFFFFF" d="M126.3,12.9h-19.8l-5,5h10.8L97.1,33.1h15.6l4.9-5H111L126.3,12.9z"/><polygon fill="#FFFFFF" points="174,12.9 174,33.1 167.6,33.1 156.4,21.9 156.4,33.1 150.1,33.1 150.1,12.9 156.4,12.9 			156.4,12.9 156.5,12.9 167.7,24.2 167.7,12.9 		"/><rect x="178" y="12.9" fill="#FFFFFF" width="6.3" height="20.2"/><polygon fill="#FFFFFF" points="194.7,17.9 194.7,20.5 204.7,20.5 204.7,25.5 194.7,25.5 194.7,28.1 207.7,28.1 207.7,33.1 			188.5,33.1 188.5,12.9 207.7,12.9 207.7,17.9 		"/><path fill="#FFFFFF" d="M226.2,26.9l-0.2-0.2c0.1-0.1,0.2-0.1,0.4-0.2c2.2-1.2,3.7-3.6,3.7-6.3c0-4-3.2-7.3-7.3-7.3H211v20.2h6.3 v-5.6h1.7l5.6,5.6h7.8L226.2,26.9z M222.6,22.2C222.6,22.2,222.6,22.2,222.6,22.2c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0,0,0	c-0.2,0.1-0.5,0.1-0.7,0.1h-4.3v-4.6h4.3c0.4,0,0.7,0.1,1,0.3c0,0,0,0,0,0c0.7,0.4,1.2,1.1,1.2,2 C223.9,21.1,223.4,21.8,222.6,22.2z"/></g></g></svg>
            </figure>
            <span>360°</span>
        </div>
      </header>
</template>

<script>
import { mapGetters } from "vuex";
import MainNav from "@/views/layout/components/MainNav";
import { EventBus } from "@/bus/event-bus.js";

export default {
  name: "AppHeader",
  components: {
      MainNav
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["gRouters", "gSize", "gTourActive"]),
    mainNavItems() {
      return (this.gRouters && this.gRouters.length?this.gRouters[0].children:[]);
    },
    isPage() {
      return this.$route.path != '/home';
    },
  },
  created() {
    EventBus.$on("doResize", () => {
      this.checkResize();
    });     
  },
  mounted() {
    this.checkResize();
  },
  methods: {
    checkResize() {
      this.$store.dispatch("setHeaderHeight", (this.$refs.domHeader?this.$refs.domHeader.getBoundingClientRect().height:90) );
    },
  }
};
</script>
