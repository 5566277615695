<template>
  <!-- eslint-disable vue/require-component-is-->
  <component v-bind="linkProps(to)" :class="linkClass" :data-pageid="uid" @click.native="onClick">
    <slot />
  </component>
</template>

<script>
import { isExternal } from "@/utils";
import { mapGetters } from "vuex";

export default {
  props: {
    to: {
      type: String,
      required: true
    },
    linkClass: {
      type: String,
      required: false
    },
    uid: {
      type: Number,
      requried: false
    },
    level: {
      type: Number,
      required: false
    }
  },
  computed: {
    ...mapGetters(["gMainnav"])
  },  
  methods: {
    isExternalLink(routePath) {
      return isExternal(routePath);
    },
    linkProps(url) {
      if (this.isExternalLink(url)) {
        return {
          is: "a",
          href: url,
          target: "_blank",
          rel: "noopener"
        };
      }
      return {
        is: "router-link",
        to: url
      };
    },
    onClick() {
      this.$emit('onLinkClicked', this.level, this.uid, this.to);
    }
  }
};
</script>
