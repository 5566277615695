import { constantRouterMap } from "@/router";

const permission = {
  state: {
    routers: constantRouterMap,
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.routers = routers;
      console.log('permission.js -> state.addRouters', state.routers);
    }
  },
  actions: {
    GenerateRoutes({ commit }, data) {
      commit("SET_ROUTERS", data);
    }      
  }
};

export default permission;
