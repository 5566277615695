import router from "./router";

router.beforeEach((to, from, next) => {
  //console.log('permission: beforeEach', to, from);
  next();
});

router.afterEach(() => {
  //unsetLoading();
  //NProgress.done() // finish progress bar
});
