<template>
  <nav role="navigation" aria-label="Main" :class="{ 'is-active': isNaviActive }" >
    <a href="/" class="togglenav" :class="{ 'is-active': isNaviActive }" @click.prevent="isNaviActive=!isNaviActive" >
      <span class="togglenav__inner"></span>
    </a>
    <ul class="nav__items nav__items--main">
      <li
        v-for="(route, idx) in displayRoutes"
        v-bind:key="idx"
        class="nav__item nav__item--main"
        :data-level="route.meta.level"
        :class="{'is-active': isActive(route), 'is-home': isHome}"
      >
        <RouterLink
          :to="route.path"
          :linkClass="linkClass"
          :uid="route.meta.pageId"
          :level="route.meta.level"
          @onLinkClicked="onLinkClicked"
        >
          <i v-if="route.meta.svgIcon" class="svgicon svgicon--page" v-html="route.meta.svgIcon" ></i><span class="navlink__title">{{ route.meta.title }}</span>
        </RouterLink>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import RouterLink from "@/components/RouterLink";

export default {
  name: "MainNav",
  components: {
    RouterLink
  },
  data() {
    return {
      linkClass: "nav__link nav__item--link",
      isNaviActive: false
    };
  },
  computed: {
    ...mapGetters(["gSize"]),
    displayRoutes() {
      return this.items ? this.items.filter( item => item.meta && item.meta.type == "mainnav" && item.path != "/home" ) : [];
    },
    isHome() {    
      return false; //this.items.filter(item => item.state.active == true).length == 0;
    }
  },
  props: {
    items: {
      type: Array,
      default: null
    }
  },
  methods: {
    isActive(route) {
      return (route?false:false);
    },
    onLinkClicked(/* level, uid, to */) {
      if (this.gSize.screen.width < 1366 && this.isNaviActive) {
        this.isNaviActive = false;
      };
    }
  }
};
</script>
