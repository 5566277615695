<template>
  <div :class="classObj" class="app-wrapper">
    <div class="wrapper wrapper--header" :class="{ 'is-hidden': !tourActive }">
      <AppHeader />
    </div>
    <app-main />
    <footer class="footer" role="contentinfo" :class="{ 'is-hidden': !tourActive }"><p>#mymountainpassion</p></footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AppMain, AppHeader } from "./components";
import { EventBus } from "@/bus/event-bus.js";
//import { Api } from "@/api";
//import axios from "axios";
import initResize from "@/utils/resize";

export default {
  name: "Layout",
  components: {
    AppMain,
    AppHeader
  },
  data() {
    return {
      scrollWheel: {
        deltaY: 0,
        keys: {
          alt: false,
          ctrl: false,
          shift: false
        },
        keyCode: 0,
        pageX: 0,
        srcElement: 0
      },
      rAFScrollTicking: false
    };
  },
  computed: {
    ...mapGetters(["gRouters", "gSize", "gMainnav", "gTourActive"]),
    device() {
      return this.$store.state.app.device;
    },
    classObj() {
      return {
        mobile: this.device === "mobile"
      };
    },
    tourActive() {
      return this.gTourActive;
    }
  },
  created() {
    EventBus.$on("doResize", () => {
      this.checkResize();
    });
    initResize();
  },
  mounted() {
    this.checkResize();
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("closeSideBar", { withoutAnimation: false });
    },
    checkResize() {
      this.$store.dispatch("setSize", {
        screen: {
          width: this.getWindowWidth(),
          height: this.getWindowHeight()
        }
      });
    },
    getWindowWidth() {
      return ( window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      );
    },
    getWindowHeight() {
      return ( window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    },
    scrollUpdate() {
      this.rAFScrollTicking = false;
      EventBus.$emit("onScrollWheel", this.scrollWheel);
    },
    handleChangeLanguage(lang) {
      this.$store.dispatch("setLanguage", lang);
      this.$nextTick(() => {
        window.location = "/";
      });
    },
  }
};
</script>
